interface ICustomer {
  id: number;
  name: string;
  email: string;
  company: string;
  payment: {
    icon: string;
    ccnumber: string;
    label: string;
  };
  date: string;
}

const customers: Array<ICustomer> = [
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Elogicals Technology Pvt. Ltd.",
    email: "admin@elogicals.com",
    company: "-",
    payment: {
      icon: "/media/svg/card-logos/mastercard.svg",
      ccnumber: "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
      label: "mastercard",
    },
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    name: "Metalxperts (India) llp",
    email: "admin@metalxperts.com",
    company: "-",
    payment: {
      icon: "/media/svg/card-logos/visa.svg",
      ccnumber: "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
      label: "visa",
    },
    date: "01 Dec 2020, 10:12 am",
  },
  
];

export { ICustomer };

export default customers;
